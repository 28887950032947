import React, { useContext, useEffect, useState } from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ServiceComponent from "../Components/Service/ServiceComponent";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { GetAllServices, GetSearchResult, AllServices } from '../Services/DataService'
import UseServices from "../Hooks/use-services";
import ServicesContext from '../Context/ServicesContext'
import ModalComponent from "../Components/Modal/ModalComponent";

export default function ServicesPage() {

  let { servicesArr, setServicesArr } = useContext(ServicesContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [services, setServices] = useState([])

  const filteredServices = AllServices.filter((service) =>
    service.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    GetAllServices().then((data) => {
      setServices(data);
    }).catch((error) => {
      console.error(error);
    });
  }, [])

  return (
    <Row className="services-container d-flex justify-content-center p-3" id="services">
      <Col xs={12} md={10} lg={9}>
        <Row className="mx-md-2 mx-5">
          <h2 className="bold">Services</h2>
          <h3 className="">Select a service to view more information.</h3>
        </Row>
        <Row className="mx-md-2 mx-5">
          <Form>
            <InputGroup className="mb-3 search-bar">
              <InputGroup.Text className="search-icon">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <FormControl
                className="search-bar"
                type="text"
                placeholder="Search for services... "
                aria-label="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </Form>
        </Row>
        <Row xs={3} md={4} lg={4} xl={6} className="btn-services-contain p-3">
          {
            filteredServices.map((service, idx) => {
              return (
                <ServiceComponent key={idx} service={service} idx={idx} />
              )
            })
          }

        </Row>
      </Col>

      <ModalComponent />
    </Row>
  );
}

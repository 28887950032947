import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CalaverasCommunityLogo from '../Images/Calaveras Enterprise.png'
import DignityHealthLogo from '../Images/Mark Twain Logo.jpg'
import PhinellsLogo from '../Images/Pinnells Logo@3x.png'
import TacoBellLogo from '../Images/editedTB.png'
import SierraPacificLogo from '../Images/Sierra Pacific Logo@3x.png'
import GurnickLogo from '../Images/Gurnick Academy@3x.png'
import GerardLogo from '../Images/Gerard.jpg'
import TrcLogo from '../Images/TRC logo.png'
import NextHome from '../Images/NextHome.png'
import Umpqua from '../Images/Umpqua.jpg'
import Columbia from '../Images/Columbia college logo.png'
import Laborers from '../Images/ACT-Ohio-Laborers.png'
import Aab from '../Images/Above and Beyond.jpg'
import './Sponsors.css'
import { getOverlayDirection } from 'react-bootstrap/esm/helpers'
export default function SponsorsPage() {
  return (
    <Row className="justify-content-center mt-3 mb-3 d-flex hiddenOverflow" id="sponsors">
      <Col xs={12} md={10} lg={9}>
        <Row className="mx-md-2 mx-2">
          <h2 className="boldTxt ">Sponsors</h2>
          <h3 className="">Thank you to our sponsors!</h3>
        </Row>
        <Row xs={1} sm={3} md={3} lg={6} className="mt-2 mx-2 p-3 sponsorRow justify-content-center">
          <Col>
            <img className="sponsorImg img-fluid" alt="Gerard Insurance Services Logo" src={GerardLogo} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="Calaveras Enterprise Logo" src={CalaverasCommunityLogo} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="The Resource Connection Logo" src={TrcLogo} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="NextHome Utica Logo" src={NextHome} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="Umpqua Bank Logo" src={Umpqua} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="Columbia College Logo" src={Columbia} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="Gurnick Logo" src={GurnickLogo} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="Liuna Laborers Logo" src={Laborers} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid " alt="Above and Beyond Logo" src={Aab} />
          </Col>
          <Col style={{ width: "350px", height: "auto" }}>
            <img className="sponsorImgDignity img-fluid " alt="Mark Twain Medica Center Logo" src={DignityHealthLogo} />
          </Col>
          <Col>
            <img className="sponsorImg img-fluid" alt="Taco Bell Logo" src={TacoBellLogo} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
